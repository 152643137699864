/* ------------------------------------*\
  #Imports
\*------------------------------------ */

@import '../../../../../assets/css/variables/variables';

/* ------------------------------------*\
  #Component styles
\*------------------------------------ */

.GeoipPopupTitle {
  font-size: $fontSize-m;
  font-weight: $font-weight-l;
  line-height: 26px;
  margin-bottom: 15px;
  text-align: center;
}

.GeoipPopupDescription {
  font-size: $fontSize-sm;
  margin-bottom: 15px;
  text-align: center;
}

.GeoipButton {
  margin: 0 auto 16px;
  max-width: 300px;
}

.GeoipButtonCancel {
  margin: 0 auto;
  max-width: 300px;
  text-align: center;
}
